import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import axios from 'src/utils/axios_remote';

const initialState = {
  tenants: [],
  tenant: {},
  isModalOpen: false,
  tenantContext: {},
};

const API_ENDPOINT = process.env.REACT_APP_CORE_API;

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    getTenantsReducer(state, action) {
      const { tenants } = action.payload;
      return { ...state, tenants }
    },
    getTenantReducer(state,action) {
      const { tenant } = action.payload;
      state.tenant = tenant;
    },
    getTenantContextReducer(state,action) {
      const { tenant } = action.payload;
      state.tenantContext = tenant;
    },
    createTenant(state, action) {
      const { tenant } = action.payload;
      state.tenants.push(tenant);
    },
    updateTenant(state, action) {
      const { tenant } = action.payload;

      state.tenants = _.map(state.tenants, (_tenant) => {
        if (_tenant.id === tenant.id) {
          return tenant;
        }

        return _tenant;
      });
    },
    deleteTenant(state, action) {
      const { tenantId } = action.payload;

      state.tenants = _.reject(state.tenants, { id: tenantId });
    },
    updateUserCount(state,action) {
      const { tenantId } = action.payload;
      
      state.tenants = state.tenants.map((tenant) => {
        if(tenant.id === tenantId) {
          tenant.users++; 
          return tenant
        }
        return tenant
      })
      // state.tenants = _.map(state.tenants, (_tenant) => {
      //   if (_tenant.id === tenantId) {
      //     _tenant.user++; 
      //     return _tenant;
      //   }

      //   return _tenant;
      // });
    }
   
  }
});

export const reducer = slice.reducer;

export const getTenantsReducer = () => async (dispatch) => {
  const response = await axios.get(`${API_ENDPOINT}/tenants`);
  const { data: tenants } = response;
  dispatch(slice.actions.getTenantsReducer({tenants}));
};
export const getTenantReducer = (tenantId) => async (dispatch) => {
  const response = await axios.get(`${API_ENDPOINT}/tenants/${tenantId}`);
  const { data: tenant } = response;
  dispatch(slice.actions.getTenantReducer({tenant}));
};

export const getTenantContextReducer = () => async (dispatch) => {
  try{
    const { tenant_id } = JSON.parse(localStorage.getItem('userContext')) || {};
    const response = await axios.get(`${API_ENDPOINT}/tenants/${tenant_id}`);
    const { data: tenant } = response;
    dispatch(slice.actions.getTenantContextReducer({tenant}));
  }catch(e){
    dispatch(slice.actions.getTenantReducer({tenant: {}}));
  }
};

export const createTenant = (data) => async (dispatch) => {
  const response = await axios.post(`${API_ENDPOINT}/tenants`, data);
  const { data: tenant} = response;
  dispatch(slice.actions.createTenant({tenant}));
};
export const updateTenant = (tenantId, update) => async (dispatch) => {
  const response = await axios.put(`${API_ENDPOINT}/tenants/${tenantId}`,update);
  const {data: tenant} = response;
  dispatch(slice.actions.updateTenant({tenant}));
};
export const deleteTenant = (tenantId) => async (dispatch) => {
  await axios.delete(`${API_ENDPOINT}/tenants/${tenantId}`);
  dispatch(slice.actions.deleteTenant({ tenantId }));
};
export const updateUserCount = (tenantId) => async(dispatch) => {
  // await axios.post('/api/tenants/remove', {
  //   tenantId
  // });

  dispatch(slice.actions.updateUserCount({ tenantId }));
}

export default slice;
