import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios_remote';

const API_ENDPOINT = process.env.REACT_APP_FILE_API;

const initialState = {
  users: [],
  candidatePanelUsers: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
  userProfile: {},
  tenantUsers: [],
  abilities: {},
  userAvatar:{
    statusCode: '',
    message: 'fetching file',
    success: false,
    url:''
  }
};

const USERSAPI = process.env.REACT_APP_USERS_API;

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserAbilitiesReducer(state, action){
      const { abilities } = action.payload;
      state.abilities = abilities;
    },
    getUsersReducer(state, action) {
      const { users } = action.payload;
      state.users = users;
    },
    getUsersByIdReducer(state, action){
      const { users } = action.payload;
      state.candidatePanelUsers = users
    },
    getTenantUsersReducer(state, action) {
      const { users } = action.payload;
      state.tenantUsers = users;
    },
    getUserProfile(state, action) {
      const { profile } = action.payload;
      state.userProfile = profile;
    },
    createUser(state, action) {
      const { user } = action.payload;
      state.users.push(user);
    },
    updateUser(state, action) {
      const { user } = action.payload;

      state.users = _.map(state.users, (_user) => {
        if (_user.id === user.id) {
          return user;
        }

        return _user;
      });
    },
    updateUserProfile(state, action) {
      const { user } = action.payload;
      state.userProfile = user;
    },
    deleteUser(state, action) {
      const { userId } = action.payload;
      const { users } = state;
      state.users = _.reject(users, { user_id: userId });
    },
    deleteTenantUser(state, action) {
      const { userId } = action.payload;
      const { tenantUsers } = state;
      state.tenantUsers = _.reject(tenantUsers, { user_id: userId });
    },
    clearAvatar(state, action) {
      state.userAvatar = {
        statusCode: '',
        message: 'fetching file',
        success: false,
        url:''
      };
    },
    getAvatar(state, action) {
      const { data = {}, error = null } = action.payload;
      const { url } = data;
      if(error?.statusCode === 404){
        console.log(404);
        state.userAvatar = {
          statusCode: '400',
          message: 'failed',
          success: false,
          url:''
        }
      } else if(url && url !== ''){
        state.userAvatar = {
          statusCode: '200',
          message: 'Success',
          success: true,
          url
        }
      } else{
        state.userAvatar = {
          statusCode: '400',
          message: 'failed',
          success: false,
          url:''
        }
      }
    }
  }
});

export const reducer = slice.reducer;

export const getUsersReducer = (tenantId) => async (dispatch) => {
  const response = await axios.post(`${USERSAPI}/getUsers`, {
    "tenant_id": tenantId,
  });
  const { data: { response: users } } = response;
  dispatch(slice.actions.getUsersReducer({users}));
};
export const getUsersByIdReducer = (panel) => async (dispatch) => {
  const response = await axios.post(`${USERSAPI}/getUsersById`, {
    "panel": panel
  });
  const { data } = response;
  dispatch(slice.actions.getUsersByIdReducer({users: data}));
};

export const getUserAbilitiesReducer = () => async (dispatch) => {
  const response = await axios.get(`${USERSAPI}/abilitiesForUser`);
  const { data: { response: abilities } } = response;
  dispatch(slice.actions.getUserAbilitiesReducer({abilities}));
};

export const getTenantUsers = (tenantId) => async (dispatch) => {
  const response = await axios.post(`${USERSAPI}/getUsers`, {
    "tenant_id": tenantId,
  });
  const { data: { response: users } } = response;
  dispatch(slice.actions.getTenantUsersReducer({users}));
};

export const getUserProfile = (userId) => async (dispatch) => {
  const response = await axios.post(`${USERSAPI}/getUser`, {
      "user_id": `${userId}`,
    });
  const { data: { response: profile } } = response;
  dispatch(slice.actions.getUserProfile({profile}));
};

export const updateUserProfile = (userId, auth0Request) => async (dispatch) => {
  const response = await axios.post(`${USERSAPI}/updateUser`, {
      "user_id": userId,
      "auth0_request": auth0Request
    });
  const { data: user } = response;
  dispatch(slice.actions.updateUserProfile({user}));
};

export const createUser = (data) => async (dispatch) => {
  const response = await axios.post(`${USERSAPI}/createUser`, data);
  const { data: user } = response;
  dispatch(slice.actions.createUser({user}));
};
export const updateUser = (data) => async (dispatch) => {
  const response = await axios.post(`${USERSAPI}/updateUser`, data);
  const { data: user } = response;
  dispatch(slice.actions.updateUser({user}));
};
export const deleteUser = (userId) => async (dispatch) => {
  await axios.post(`${USERSAPI}/deleteUser`, {
    "user_id": userId
  });
  dispatch(slice.actions.deleteUser({ userId }));
};

export const deleteTenantUser = (userId) => async (dispatch) => {
  await axios.post(`${USERSAPI}/deleteUser`, {
    "user_id": userId
  });
  dispatch(slice.actions.deleteTenantUser({ userId }));
};

export const getUserAvatar = (key, userId) => async (dispatch) => {
  const  response = await axios.get(`${API_ENDPOINT}/getFile/userId/${userId}/key/${key}`);
  console.log('getEvents response', response)
  dispatch(slice.actions.getAvatar(response));
};

export const clearAvatar = () => async (dispatch) => {
  dispatch(slice.actions.clearAvatar());
};




export default slice;
