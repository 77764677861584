import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios_remote';
const API_ENDPOINT = process.env.REACT_APP_CORE_API;

const initialState = {
  notifications: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state, action) {
      const { notifications } = action.payload;
      state.notifications = notifications;
    },
    markAsRead(state, action) {
      state.notifications = [];
    }
  }
});

export const reducer = slice.reducer;

export const getNotifications = () => async (dispatch) => {
  const response = await axios.get(`${API_ENDPOINT}/notifications`);
  const { data: notifications } = response;
  dispatch(slice.actions.getNotifications({notifications}));
};

export const markAsRead = () => async (dispatch) => {
  await axios.put(`${API_ENDPOINT}/notifications/markAsRead`);
  dispatch(slice.actions.markAsRead());
}

export default slice;
