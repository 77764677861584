import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios_remote';
import _ from 'lodash';

const API_ENDPOINT = process.env.REACT_APP_CORE_API;

const initialState = {
  requisitions: [],
  requisition: {},
  requisitionSkills: {},
  isModalOpen: false
};

const slice = createSlice({
  name: 'requisition',
  initialState,
  reducers: {
    getRequisitionsReducer(state, action) {
      const { requisitions } = action.payload;

      state.requisitions = requisitions;
    },
    getRequisitionReducer(state, action) {
      const { requisition } = action.payload;
      state.requisition = requisition;
      state.requisitions = _.map(state.requisitions, _requisition => {
        if (_requisition.id === requisition.id) {
          return requisition;
        }

        return _requisition;
      });
    },
    getRequisitionSkillsReducer(state, action) {
      const { requisitionSkills } = action.payload;
      state.requisitionSkills = requisitionSkills;
    },

    createRequisition(state, action) {
      const { requisition } = action.payload;
      state.requisitions = [...state.requisitions, requisition];
    },
    addCandidateToRequisition(state, action) {
      const { requisition } = action.payload;
      state.requisitions = _.map(state.requisitions, _requisition => {
        if (_requisition.id === requisition.id) {
          return requisition;
        }

        return _requisition;
      });
    },
    updateRequisition(state, action) {
      const { requisition } = action.payload;
      state.requisition = requisition;
      state.requisitions = _.map(state.requisitions, _requisition => {
        if (_requisition.id === requisition.id) {
          return requisition;
        }

        return _requisition;
      });
    },
    deleteRequisition(state, action) {
      const { requisitionId } = action.payload;

      state.requisitions = _.reject(state.requisitions, { id: requisitionId });
    }
  }
});

export const reducer = slice.reducer;

export const getRequisitionsReducer = () => async dispatch => {
  const response = await axios.get(`${API_ENDPOINT}/requisitions`);
  const { data: requisitions } = response;
  dispatch(slice.actions.getRequisitionsReducer({requisitions}));
};

export const getRequisitionReducer = requisitionId => async dispatch => {
  const response = await axios.get(`${API_ENDPOINT}/requisitions/${requisitionId}`);
  const { data: requisition } = response;
  dispatch(slice.actions.getRequisitionReducer({requisition}));
};

// export const getRequisitionSkillsReducer = requisitionName => async dispatch => {
//   const response = await axios.get(
//     `/api/requisitionSkills/?requisitionName=${requisitionName}`
//   );

//   dispatch(slice.actions.getRequisitionSkillsReducer(response.data));
// };
export const createRequisition = data => async dispatch => {
  const response = await axios.post(`${API_ENDPOINT}/requisitions`, data);
  const { data: requisition } = response;
  dispatch(slice.actions.createRequisition({requisition}));
};

export const addCandidateToRequisition = (requisitionId, candidateId) => async dispatch => {
  const response = await axios.put(`${API_ENDPOINT}/requisitions/${requisitionId}/add-candidate`, {candidate_id: candidateId});
  const { data: requisition } = response;
  dispatch(slice.actions.addCandidateToRequisition({requisition}));
};

export const updateRequisition = (requisitionId, update) => async dispatch => {
  const response = await axios.put(`${API_ENDPOINT}/requisitions/${requisitionId}`, update);
  const { data: requisition } = response;
  dispatch(slice.actions.updateRequisition({requisition}));
};
export const deleteRequisition = requisitionId => async dispatch => {
  await axios.delete(`${API_ENDPOINT}/requisitions/${requisitionId}`);
  dispatch(slice.actions.deleteRequisition({ requisitionId }));
};

export default slice;
