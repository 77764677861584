import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import SplashScreen from 'src/components/SplashScreen';
import { auth0Config } from 'src/config';
import { useDispatch } from 'src/store';
import { getUserAbilitiesReducer, getUserAvatar } from 'src/slices/user';
import * as Sentry from "@sentry/react";

let auth0Client = null;

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  logout: () => { }
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const globalDispatch = useDispatch();
  const loginWithPopup = async (options) => {
    // console.log('Auth0 client ' + auth0Client)
    await auth0Client.loginWithPopup(options);

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      const userToken = await auth0Client.getIdTokenClaims();
      const userPayload = {
        id: user.sub,
        avatar: user.picture,
        email: user.email,
        name: user.name,
        role: user['https://interviewworks.com/roles'][0],
        tenant_id: user['https://interviewworks.com/tenant_id'],
        tier: 'Premium'
      }
      Sentry.setUser({ email: user.email, id: user.sub, name: user.name, role: user['https://interviewworks.com/roles'][0], tenant_id: user['https://interviewworks.com/tenant_id']});

      localStorage.setItem('userContext', JSON.stringify(userPayload));
      localStorage.setItem('userJWT', userToken?.__raw);
      await globalDispatch(getUserAbilitiesReducer());
      await globalDispatch(getUserAvatar('avatar',user.sub.split("|")[1]));
      // console.log("user " + JSON.stringify(user))

      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...userPayload
          }
        }
      });
    }
  };

  const logout = () => {
    auth0Client.logout();
    localStorage.removeItem('userContext');
    localStorage.removeItem('userJWT');
    Sentry.setUser(null);
    dispatch({
      type: 'LOGOUT'
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        console.log('Auth0 configs ' + JSON.stringify(auth0Config))
        if (auth0Config && auth0Config.client_id && auth0Config.domain && auth0Config.redirect_uri === 'http://localhost:3000') {
          console.log('Setting dev Auth0 configs')
          auth0Client = new Auth0Client({
            ...auth0Config
          });
        } else {
          console.log('Setting production Auth0 configs')
          auth0Client = new Auth0Client({
            ...auth0Config,
            redirect_uri: auth0Config.redirect_uri,
          });
        }


        await auth0Client.checkSession();

        console.log('Auth0 client ' + auth0Client)

        const isAuthenticated = await auth0Client.isAuthenticated();
        const userToken = await auth0Client.getIdTokenClaims();
        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          console.log("user isAuthenticated " + JSON.stringify(user))


          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          const userPayload = {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            role: user['https://interviewworks.com/roles'][0],
            tenant_id: user['https://interviewworks.com/tenant_id'],
            tier: 'Premium'
          }
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: {
                ...userPayload
              }
            }
          });
          localStorage.setItem('userContext', JSON.stringify(userPayload));
          localStorage.setItem('userJWT', userToken?.__raw);
          await globalDispatch(getUserAbilitiesReducer());
          await globalDispatch(getUserAvatar('avatar',user.sub.split("|")[1]));
          
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, [globalDispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Auth0',
        loginWithPopup,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;