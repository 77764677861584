import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    errors: [],
};

const slice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        addError(state, action) {
            console.log('error.js');
            const { error} = action.payload;
            state.errors = [...state.errors, error];
        },
        clearErrors(state,action) {
            state.errors = [];
        }
    }
});

export const addError = (error) => (dispatch) =>{
    dispatch(slice.actions.addError({error}));
}

export const clearErrors =() =>(dispatch) =>{
    dispatch(slice.actions.clearErrors());
}

export const reducer = slice.reducer;

export default slice;