import { createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash';
// import _ from 'lodash';
import axios from 'src/utils/axios';
import { orders} from 'src/__mocks__/orders'
import {activities, products, referrals} from 'src/__mocks__/reports'
import {sourceofhire, candidatesineachstage} from 'src/__mocks__/dashboard2reports'
const initialState = {
  applicationStatData: [],
  candidatesineachstage: candidatesineachstage,
  dataCards: [],
  interviewData: [],
  sourceofhire:sourceofhire,
  orders: orders,
  activities: activities,
  products: products,
  referrals: referrals,
  loadingData: true,
  loadingEarningData: true,
  loadCardData: true,
  loadingInterviewData: true,
  loadingSourceData: true,
  loadingOrderData: true,
  loadingActivityData: true,
  loadingProfitableProdData: true,
  loadingReferrals: true,
  chartOptions: [],
  timeRangeSelected: 'last_30_days'
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    //Add actions for dashboard charts
    getApplicationStat(state, action) {
      const { applicationsstat } = action.payload;
      state.loadingData = false;
      state.applicationStatData = applicationsstat;
    },
    getCandidatesineachstage(state, action) {
      const { candidatesineachstage } = action.payload;
      state.loadingEarningData = false;
      state.candidatesineachstage = candidatesineachstage;
    },
    getDataCards(state, action) {
      const { cards } = action.payload;
      state.loadCardData = false;
      state.dataCards = cards;
    },
    getInterviewData(state, action) {
      const { applicationsstat } = action.payload;
      state.loadingInterviewData = false;
      state.interviewData = applicationsstat;
    },
    getSourceofHireData(state, action) {
      const { sourceofhire } = action.payload;
      state.loadingSourceData = false;
      state.sourceofhire = sourceofhire;
    },
    getOrders(state, action) {
      const { orders } = action.payload;
      state.loadingOrderData = false;
      state.orders = orders;
    },
    getCustomerActivity(state, action) {
      const { activities } = action.payload;
      state.loadingActivityData = false;
      state.activities = activities;
    },
    getProfProducts(state, action) {
      const { products } = action.payload;
      state.loadingProfitableProdData = false;
      state.products = products;
    },
    getReferrals(state, action) {
      const { referrals } = action.payload;
      state.loadingReferrals = false;
      state.referrals = referrals;
    },
    setDashboardTimeRange(state,action) {
      // const {timeRange} = action.payload;
      console.log(action.payload);
      state.timeRangeSelected = action.payload;
    }
  }
});

export const reducer = slice.reducer;


// Dispatch actions for the reducer
export const getApplicationStat = () => async (dispatch) => {
  
  const response = await axios.get(`/api/dashboard2reports/applicationsstat`);
  dispatch(slice.actions.getApplicationStat(response.data));
};

export const getCandidatesineachstage = () => async (dispatch) => {
  
  const response = await axios.get(`/api/dashboard2reports/candidatesineachstage`);
  dispatch(slice.actions.getCandidatesineachstage(response.data));
};

export const getDataCards = (timeRangeSelected) => async (dispatch) => {
  setTimeout(async () => {
    const response = await axios.get(`/api/cards/?selectedTimeRange=${timeRangeSelected}`);
  
    dispatch(slice.actions.getDataCards(response.data));
  }, 1000)

};
export const getInterviewData = () => async (dispatch) => {
  
  const response = await axios.get(`/api/dashboard2reports/numberofinterviews`);
  console.log(response)
  dispatch(slice.actions.getInterviewData(response.data));
};

export const getSourceofHireData = () => async (dispatch) => {
  
  const response = await axios.get(`/api/dashboard2reports/sourceofhire`);
  console.log(response)
  dispatch(slice.actions.getSourceofHireData(response.data));
};
export const getOrders = () => async (dispatch) => {
  
  const response = await axios.get(`/api/reports/latest-orders`);
  console.log(response)
  dispatch(slice.actions.getOrders(response.data));
};
export const getCustomerActivity = () => async (dispatch) => {
  
  const response = await axios.get(`/api/reports/customer-activity`);
  console.log(response)
  dispatch(slice.actions.getCustomerActivity(response.data));
};
export const getProfProducts = () => async (dispatch) => {
  
  const response = await axios.get(`/api/reports/profitable-products`);
  console.log(response)
  dispatch(slice.actions.getProfProducts(response.data));
};
export const getReferrals = () => async (dispatch) => {
  
  const response = await axios.get(`/api/reports/top-referrals`);
  console.log(response)
  dispatch(slice.actions.getReferrals(response.data));
};
export const setDashboardTimeRange = (timeRange) => async  (dispatch) => {
  
 //  const response = await axios.get(`/api/reports/top-referrals`);
 //  console.log(response)
  dispatch(slice.actions.setDashboardTimeRange(timeRange));
};
export default slice;

