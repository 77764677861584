import mock from 'src/utils/mock';
import moment from 'moment';
import _ from 'lodash';
import { parseQueryString } from './util';
// import { parseQueryString } from './util';
export let interviews = [
  {
    id: '7e887ac47eed253091be34cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 'ye887ac47eed253091brtcb',
    candidate_id: 'se887ac47eed253091bewtcb',
    scheduled_on: moment('2020-05-12')
    .toDate()
    .getTime(),
    scheduled_time:moment(new Date('2020-05-15'))
    .toDate()
    .getTime(),
    meeting_id: 'vt887ac47eed253091be23cb',
    stage: 'First',
    status: 'In progress',
    panel: ['Rajvi', 'Praveen'],
    requisition:'Front End Developer',
    candidate:'	Miriam Wagner',
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
  },
  {
    id: '4e887ac47eed253091be23cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 'te887ac47eed253091be45cb',
    candidate_id: 'df887ac47eed253091beulcb',
    scheduled_on: moment()
    .toDate()
    .getTime(),
    meeting_id: 'gh887ac47eed253091be99cb',
    stage: 'First',
    status: 'Completed',
    panel: ['Allison', 'Berry'],
    requisition:'Front End Developer',
    candidate:'	Carlos Abbott',
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
  },
  {
    id: '8e887ac47eed253091be67cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 'dfe887ac47eed253091bertcb',
    candidate_id: 'kl887ac47eed253091befgcb',
    scheduled_on: moment()
    .toDate()
    .getTime(),
    meeting_id: 'as887ac47eed253091be33cb',
    stage: 'First',
    status: 'Incomplete',
    panel: ['Charles', 'Naveen'],
    requisition:'Front End Developer',
    candidate:'Bradley Wilkerson',
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
  },
  {
    id: 'be887ac47eed253091be45cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 'yue887ac47eed253091be23cb',
    candidate_id: 'zx887ac47eed253091be45cb',
    scheduled_on: moment()
    .toDate()
    .getTime(),
    meeting_id: 're887ac47eed253091be68cb',
    stage: 'First',
    status: 'In progress',
    panel: ['Edward', 'Savannah'],
    requisition:'Front End Developer',
    candidate:'Virginia Andrews',
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
  },
  {
    id: '9e887ac47eed253091be45cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 'ute887ac47eed253091beghcb',
    candidate_id: 'bt887ac47eed253091beopcb',
    scheduled_on: moment()
    .toDate()
    .getTime(),
    meeting_id: 'vv887ac47eed253091be56cb',
    stage: 'First',
    status: 'Completed',
    panel: ['Scarlet', 'Rahul'],
    requisition:'Front End Developer',
    candidate:'Kelly Snyder',
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
  },
  {
    id: '2e887ac47eed253091be39cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 'tye887ac47eed253091beopcb',
    candidate_id: 'gh887ac47eed253091begscb',
    scheduled_on: moment()
    .toDate()
    .getTime(),
    meeting_id: 'sd887ac47eed253091be89cb',
    stage: 'First',
    status: 'In progress',
    panel: ['Tim','Vipul'],
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
  },
  {
    id: 'ge887ac47eed253091be45cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 're887ac47eed253091beklcb',
    candidate_id: 'xc887ac47eed253091be34cb',
    scheduled_on: moment()
    .toDate()
    .getTime(),
    meeting_id: 'we887ac47eed253091bekkcb',
    stage: 'First',
    status: 'Incomplete',
    panel: ['Prithvi', 'Rajvi'],
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
  },
  {
    id: '7e887ac47eed253091be99cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 'rte887ac47eed253091befgcb',
    candidate_id: 'kh887ac47eed253091be45cb',
    scheduled_on: moment()
    .toDate()
    .getTime(),
    meeting_id: 'tt887ac47eed253091be21cb',
    stage: 'First',
    status: 'In progress',
    panel: ['Savannah', 'Tim'],
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
  },
  {
    id: '6e887ac47eed253091be78cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 'fe887ac47eed253091bekdcb',
    candidate_id: 'bve887ac47eed253091be23cb',
    scheduled_on: moment()
    .toDate()
    .getTime(),
    meeting_id: 'fr887ac47eed253091be14cb',
    stage: 'First',
    status: 'Hired',
    panel: ['Charles', 'John'],
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
  },
  {
    id: '3e887ac47eed253091be90cb',
    tenant_id: '5e887ac47eed253091be10cb',
    jobreq_id: 'ee887ac47eed253091berucb',
    candidate_id: 'ghe887ac47eed253091befhcb',
    scheduled_on: moment()
    .toDate()
    .getTime(),
    meeting_id: '45887ac47eed253091be67cb',
    stage: 'First',
    status: 'In progress',
    panel: ['John', 'Tim'],
    createdAt: moment()
    .toDate()
    .getTime(),
    updatedAt: moment()
    .toDate()
    .getTime(),
    isFeedbackCreated: false,
    feedbackId: ''
    
  },
 
];

mock.onGet('/api/interviews').reply(() => {
  return [200, { interviews }];
});
mock.onGet('/api/interviews/getInterviewDataCards').reply(() => {
  const interviewDataCards= [
    {total: interviews.length,
      inProgress:_.filter(interviews, {status: 'In progress'}).length,
      completed: _.filter(interviews, {status: 'Completed'}).length,
      inComplete: _.filter(interviews, {status: 'In Complete'}).length,
      hired: _.filter(interviews, {status: 'Hired'}).length
    }
  ]
  console.log('from interview mocks', interviews, interviewDataCards)
  // const interviewDataCards = []
  
  return [200, { interviewDataCards }];
});

mock.onGet(/api\/interview\/?.*/).reply((config) => {

  const {interviewId} = parseQueryString(config.url);
  return [200, { interview: interviews.filter(interview => interview.id === interviewId)[0]}];
});

mock.onPost('/api/interviews/addinterview').reply((request) => {
  try {
    const {panel, stage, status, title, scheduled_on,scheduled_time, jobreq_id, candidate_id} = JSON.parse(request.data);
    let interview = {
      id: '5e887ac47eed253091be10cb123' +  Math.floor(Math.random() * 30),
      panel, 
      stage, 
      status, 
      title,
      scheduled_on,
      scheduled_time,
      jobreq_id,
      candidate_id
    }
    interviews.push(interview)
    console.log(interview)
    interview = [interview];
   

    return [200, { interview }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onPost('/api/interviews/editinterview').reply((request) => {
  try {
    const { interviewId, update } = JSON.parse(request.data);
    let interview = null;

    interviews = _.map(interviews, (_interview) => {
      if (_interview.id === interviewId) {
        _.assign(_interview, { ...update });
        interview = _interview;
      }
      return _interview;
    });

    return [200, { interview }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
mock.onPost('/api/interviews/addFeedbacktoInterview').reply((request) => {
  try {
    const { interviewId, feedbackId } = JSON.parse(request.data);
    const feedbackIdToinsert = {feedbackId: feedbackId, isFeedbackCreated: true}
    let interview = null;
    console.log(interview)
    interviews = _.map(interviews, (_interview) => {
      if (_interview.id === interviewId) {
        _.assign(_interview, {...feedbackIdToinsert} );
        interview = _interview;
      }
      return _interview;
    });
    console.log(interview)
    return [200, { interview }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
mock.onPost('/api/interview/remove').reply((request) => {
  try {
    const { interviewId } = JSON.parse(request.data);

    interviews = _.reject(interviews, { id: interviewId });

    return [200, { interviewId }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});

