import mock from 'src/utils/mock';
import moment from 'moment';
import _ from 'lodash';
import { parseQueryString } from './util';
export let tenants = [
  {
    id: '5e887ac47eed253091be10cb',
    avatar: '/static/images/avatars/apple_logo.png',
    address: '123 maple rd',
    phone: '123-456-6070',
    city: 'Cleveland',
    country: 'United States',
    email: 'cao.yu@devias.io',
    name: 'Apple',
    state: 'Ohio',
    website: 'www.apple.com',
    status: 'Active',
    createdAt: moment()
      .toDate()
      .getTime(),
    users: 2,
  },
  {
    id: '5e887b209c28ac3dd97f6db5',
    avatar: '/static/images/avatars/google_logo.png',
    address: '450 heritage rd',
    phone: '123-456-9960',
    city: 'Atlanta',
    country: 'USA',
    email: 'alex.richardson@devias.io',
    name: 'Google',
    state: 'Georgia',
    website: 'www.google.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    status: 'Inactive',
    users: 3,
  },
  {
    id: '5e887b7602bdbc4dbb234b27',
    avatar: '/static/images/avatars/avatar_5.png',
    address: '145 vickery rd',
    phone: '123-456-2345',
    city: 'North Canton',
    country: 'USA',
    email: 'anje.keizer@devias.io',
    name: 'InterviewWorks',
    state: 'Ohio',
    website: 'www.interviewworks.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    status: 'Inactive',
    users: 0,
  },
  {
    id: '5e86809283e28b96d2d38537',
    avatar: '/static/images/avatars/tesla_logo.png',
    address: '123 maple rd',
    phone: '123-456-6070',
    city: 'Madrid',
    country: 'Spain',
    email: 'katarina.smith@devias.io',
    name: 'tesla',
    state: 'Madrid',
    website: 'www.tesla.com',
    status: 'Active',
    createdAt: moment()
    .toDate()
    .getTime(),
    users: 3,
  },
  {
    id: '5e86805e2bafd54f66cc95c3',
    avatar: '/static/images/avatars/apple_logo.png',
    address: '230 brookstone rd',
    phone: '123-456-5890',
    city: 'San Diego',
    country: 'USA',
    email: 'adam.denisov@devias.io',
    name: 'apple',
    state: 'California',
    website: 'www.apple.com',
    status: 'Active',
    createdAt: moment()
      .toDate()
      .getTime(),
    users: 5,

  },
  {
    id: '5e887a1fbefd7938eea9c981',
    avatar: '/static/images/avatars/gm_logo.png',
    address: '431 lincoln rd',
    phone: '123-456-5890',
    city: 'Berkeley',
    country: 'USA',
    email: 'miller.edwards@devias.io',
    name: 'GM',
    state: 'California',
    website: 'www.generalmotors.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    status: 'Inactive',
    users: 5,

  },
  {
    id: '5e887d0b3d090c1b8f162003',
    avatar: '/static/images/avatars/samsung_logo.png',
    address: '432 brookwood rd',
    phone: '123-456-2567',
    email: 'emilee.simchenko@devias.io',
    city: 'Carson City',
    country: 'USA',
    name: 'Samsung',
    state: 'Nevada',
    website: 'www.samsung.com',
    status: 'Active',
    createdAt: moment()
      .toDate()
      .getTime(),
    users: 5,
  },
  {
    id: '5e88792be2d4cfb4bf0971d9',
    avatar: '/static/images/avatars/tesla_logo.png',
    address: '687 heritage rd',
    phone: '123-456-5678',
    city: 'Los Angeles',
    country: 'USA',
    email: 'elliott.stone@devias.io',
    name: 'Tesla',
    state: 'California',
    website: 'www.tesla.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    status: 'Inactive',
    users: 5,
  },
  {
    id: '5e8877da9a65442b11551975',
    avatar: '/static/images/avatars/hp_logo.png',
    address: '345 vintage rd',
    phone: '123-456-2234',
    city: 'Murray',
    country: 'USA',
    email: 'shen.zhi@devias.io',
    name: 'HP',
    state: 'Utah',
    website: 'www.hp.com',
    status: 'Active',
    createdAt: moment()
      .toDate()
      .getTime(),
    users: 5,
  },
  {
    id: '5e8680e60cba5019c5ca6fda',
    avatar: '/static/images/avatars/dell_logo.png',
    address: '402 history rd',
    phone: '123-456-7709',
    city: 'Salt Lake City',
    country: 'USA',
    email: 'merrile.burgett@devias.io',
    name: 'Dell',
    state: 'Utah',
    website: 'www.dell.com',
    status: 'Active',
    createdAt: moment()
      .toDate()
      .getTime(),
    users: 2
  }
];
mock.onGet('/api/tenants').reply(() => {


  return [200, { tenants }];
});

mock.onGet(/api\/tenant\/?.*/).reply((config) => {
  const {tenantId} = parseQueryString(config.url);
  return [200, { tenant: tenants.filter(tenant => tenant.id === tenantId)[0]}];
});

mock.onPost('/api/tenants/addtenant').reply((request) => {
  try {
    const {address, email, city, country,name,state,website, status} = JSON.parse(request.data);
    let tenant = {
      id: '5e887ac47eed253091be10cb123',
      avatar: '/static/images/avatars/avatar_3.png',
      address,
      city,
      country,
      email,
      name,
      state,
      website,
      status: status ? 'Active' : 'Inactive'
    }
    tenant = [tenant];

    return [200, { tenant }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
mock.onPost('/api/tenants/edittenant').reply((request) => {
  try {
    const { tenantId, update } = JSON.parse(request.data);
    let tenant = null;

    tenants = _.map(tenants, (_tenant) => {
      if (_tenant.id === tenantId) {
        _.assign(_tenant, { ...update });
        tenant = _tenant;
      }

      return _tenant;
    });

    return [200, { tenant }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
mock.onPost('/api/tenants/remove').reply((request) => {
  try {
    const { tenantId } = JSON.parse(request.data);

    tenants = _.reject(tenants, { id: tenantId });

    return [200, { tenantId }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
