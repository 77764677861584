import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';
import axios_remote from 'src/utils/axios_remote';

const API_ENDPOINT = process.env.REACT_APP_USERS_API;
// const CANDIDATE_API_ENDPOINT = process.env.REACT_APP_CORE_API

const initialState = {
  events: {
    candidate: [],
    interviewPanel: []
  },
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'interviewscheduleavailability',
  initialState,
  reducers: {
    getEvents(state, action) {
      const { events, calendarType } = action.payload;
      state.events[calendarType] = events;
    },
    changeEvents(state, action) {
      const { events, calendarType } = action.payload;
      state.events[calendarType] = events;
    },
    createEvent(state, action) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    selectEvent(state, action) {
      const { eventId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = false;
      state.selectedRange = {
        start,
        end
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const reducer = slice.reducer;

export const getEvents = (userId, calendarType) => async (dispatch) => {
  // console.log(81, calendarType, userId, calendarType === 'interviewPanel');
  // if (calendarType === 'interviewPanel') {
    const response = await axios_remote.post(`${API_ENDPOINT}/getUser`, {
      'user_id': userId
    });
    const { data: { response: profile } } = response;
    const { user_metadata: { availability: events = [] } = {} } = profile;
    dispatch(slice.actions.getEvents({ events, calendarType }));
  // } else if (calendarType === 'candidate') {
  //   const response = await axios.get('/api/candidateavailability/events');
  //   console.log('candidate availability response', JSON.stringify(response))
  //   const { data } = response;
  //   dispatch(slice.actions.getEvents({ events: data, calendarType }));
  // }
};

export const createEvent = (data) => async (dispatch) => {
  const response = await axios.post('/api/candidateavailability/events/new', data);

  dispatch(slice.actions.createEvent(response.data));
};

export const changeEvents = (userId, calendarType, data) => async (dispatch) => {
  console.log('changeEvents99999999',calendarType)
  if (calendarType === 'interviewPanel') {
    const { data: userProfile } = await axios_remote.post(`${API_ENDPOINT}/userAvailability/${userId}`, data);
    const { user_metadata: { availability: events = [] } = {} } = userProfile;
    dispatch(slice.actions.getEvents({ events }));
  }else {
        console.log("change event for candidate")
        const response = await axios.post('/api/candidateavailability/events/new', data);
        dispatch(slice.actions.createEvent(calendarType, response.data));
  }
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (eventId, update) => async (dispatch) => {
  const response = await axios.post('/api/candidateavailability/events/update', {
    eventId,
    update
  });

  dispatch(slice.actions.updateEvent(response.data));
};

export const deleteEvent = (eventId) => async (dispatch) => {
  await axios.post('/api/candidateavailability/events/remove', {
    eventId
  });

  dispatch(slice.actions.deleteEvent({ eventId }));
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRange({
    start: start.getTime(),
    end: end.getTime()
  }));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
