import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffff",
    paddingTop: 100,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  settings:{
    infinite: true,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000
}
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  const settings = {
    infinite: true,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000
};

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              {/* <Typography
                variant="overline"
                color="secondary"
              >
                Introducing
              </Typography> */}
              <Typography
                variant="h1"
                color="#0000"
                style= {{ color: "#5850EC"}}
              >
                INTERVIEWWORKS
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  color="#0000"
                  style= {{fontFamily: "Geneva"}}
                >
                  SaaS solution that helps manage the Interview Process and hiring top Talent. InterviewWorks is a one-stop solution for scheduling, conducting, and managing Interviews.
                  <br></br> <br></br>
                  {/* InterviewWorks has been established to elevate and digitize the current Interview process for Recruiters, HR, Hiring Managers, Interviewers, and Interviewees. The SaaS platform leverages innovative technologies, such as Voice recognition, Image Processing, Hiring Analytics enhanced by Machine Learning and AI capabilities. We are continuously adding new capabilities using Agile and Nimble methodologies to enhance the user experience and customer retention. */}
                </Typography>
              </Box>
              <Box mt={3}>
                {/* <Grid
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      30+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Demo Pages
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      UX
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Complete Flows
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h1"
                      color="secondary"
                    >
                      300+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Components
                    </Typography>
                  </Grid>
                </Grid> */}
              </Box>
              {/* <Box mt={3}>
                <img
                  alt="Javascript"
                  className={classes.technologyIcon}
                  src="/static/images/javascript.svg"
                />
                <img
                  alt="Typescript"
                  className={classes.technologyIcon}
                  src="/static/images/typescript.svg"
                />
              </Box> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div>
              
              
              <Slider {...settings} style={{  textAlign: 'center', paddingTop:'10%', color: 'white' }}>
                
              <div className={classes.image}>
              <img
                  alt="Presentation"
                  src="/static/home/header_5.gif"
                />
              </div>
              <div> className={classes.image}
              <img
                  alt="Presentation"
                  src="/static/home/header_2.gif"
                />
              </div>
              <div className={classes.image}>
              <img
                  alt="Presentation"
                  src="/static/home/header_4.gif"
                />
              </div>
              <div className={classes.image}>
              <img
                  alt="Presentation"
                  src="/static/home/header_3.gif"
                />
              </div>
              <div className={classes.image}>
              <img
                  alt="Presentation"
                  src="/static/home/header_6.gif"
                />
              </div>
            </Slider>
            
              {/* <div >
                <img
                  alt="Presentation"
                  src="/static/home/header_1.gif"
                />
              </div> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
