// import { getMail } from 'src/slices/mail';
import mock from 'src/utils/mock';

export let candidates = [
  {
    id: 'bt887ac47eed253091beopcb',
    name: 'Jane Simpson',
    email: 'kelly@getMail.com',
    feedbackId: 'dsgsf7ac47'
  },
  {
    id: 'zx887ac47eed253091be45cb',
    name: 'Charlie Docker',
    email: 'kelly@getMail.com',
    feedbackId: 'ehjsfahc50'
  },
  {
    id: 'kl887ac47eed253091befgcb',
    name: 'John Doe',
    email: 'kelly@getMail.com',
    feedbackId: 'ihbadgasc50'
  },
  {
    id: 'se887ac47eed253091bewtcb',
    name: 'Varun Singh',
    email: 'kelly@getMail.com',
    feedbackId: 'ghssnsdbc50'
  },
  {
    id: 'df887ac47eed253091beulcb',
    name: 'Rishabh Kumar',
    email: 'kelly@getMail.com',
    feedbackId: 'ghssnsdbc50'
  }
];

mock.onGet('/api/candidates').reply(() => {
  return [200, { candidates }];
});

mock.onPost('/api/candidates/addcandidate').reply(request => {
  try {
    const { name, email } = JSON.parse(request.data);
    let candidate = {
      id: '5e887ac47eed253091be10cb123',
      name,
      email
    };
    candidate = [candidate];

    return [200, { candidate }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
