import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {

  Container,
  Typography,
  makeStyles,
  CardMedia,
  CardContent,
  CardActions,
  Card
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#5850EC",
    paddingTop: 30,
    paddingBottom: 50,
    '& .MuiPaper-root' :{ color : "black"

    },
    '& .MuiTypography-colorTextSecondary ' :{
      color: "black"
  }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  media: {
    width:180,
    height: 200,
    marginBottom: 25,
    // backgroundColor: "#fff",
    //margin: "2%"
  },
  card: {
    backgroundColor: "#f7f7f7",
    width:550,
    height: 450,
    //marginRight: "4%",
    margin: "1%",

    paddingTop: '5%',
    textAlignLast: "center",
    borderRadius: "4%",
    // boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19"

   
    
  }
}));


const Features = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          {/* Explore InterviewWorks Kit Pro */}
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="#fffff"
          style= {{marginTop: "2%", marginBottom: "2%", color: "white", fontFamily: "sans-serif"}}
        >
          Features
        </Typography>
        <div style= {{marginTop: "2%", display : "flex"}}>
          <Card className= {classes.card}>
      <CardMedia
      className="media"
      image="/static/home/scheduling_feature.svg"
      title="Scheduling feature"
      />
      <CardContent   >
      <img
        className= {classes.media} 
            alt="Presentation"
            src="/static/home/scheduling_feature.svg"
                />
      <h3 style= {{fontFamily: "sans-serif", marginBottom: "9%"}} >Scheduling</h3>
      <br></br>
        {/* <Typography variant="body2" color="textSecondary" component="p" style={{ fontSize: 13, textAlign: "center", fontFamily: "Geneva"}}>
        Quickly schedule interview sessions with the Interview panel and potential hires.
        </Typography> */}
      </CardContent>
      <CardActions disableSpacing>
        
      </CardActions>
      
    </Card>
    <Card className= {classes.card}>
    <CardContent className="card-text">
    <img
        className= {classes.media} 
            alt="Presentation"
            src="/static/home/speakeridentify_feature.svg"
                />
      <h3 style= {{textAlign : "center", fontFamily: "sans-serif", marginBottom: "9%"}}>Audio and Video Conference </h3>
      <br></br>
        {/* <Typography variant="body2" color="textSecondary" component="p" style={{ fontSize: 13, textAlign: "center"}}>
        Cloud based Audio and Video Conference solution that provides a great User Experience on various devices(Web and Mobile).  
        Allows Real-time feedback and playback of recorded sessions enable rapid assessment. 
        </Typography> */}
      </CardContent>
      <CardMedia
        className="media2"
        image="img/videocall_feature.svg"
        title="Video Call feature"
        
        
      />
      
      <CardActions disableSpacing>
        
      </CardActions>
      
    </Card>
    <Card className= {classes.card}>
      <CardMedia
        className="media"
        image="img/speakeridentify_feature.svg"
        title="Speaker identification feature"

      />
      <CardContent className="card-text">
      <img
        className= {classes.media} 
            alt="Presentation"
            src="/static/home/videocall_feature.svg"
                />
      <h3 style= {{textAlign: "center", fontFamily: "sans-serif"}}>Speaker Identification and Verification </h3>
      <br></br>
        {/* <Typography variant="body2" color="textSecondary" component="p" style={{ fontSize: 13, textAlign: "center", fontFamily: "Geneva"}}>
        Active speaker recognition and verification to identify the speakers in an Interview session. 
        </Typography> */}
      </CardContent>
      <CardActions disableSpacing>
        
      </CardActions>
      
    </Card>
    <Card className= {classes.card}>
      <CardMedia
        className="media"
        image="img/speakeridentify_feature.svg"
        title="Speaker identification feature"

      />
      <CardContent className="card-text">
      <img
        className= {classes.media} 
            alt="Presentation"
            src="/static/home/feedback_feature.svg"
                />
      <h3 style= {{textAlign: "center", fontFamily: "sans-serif"}}>Feedback and Decision Making </h3>
      <br></br>
        {/* <Typography variant="body2" color="textSecondary" component="p" style={{ fontSize: 13, textAlign: "center", fontFamily: "Geneva"}}>
        Active speaker recognition and verification to identify the speakers in an Interview session. 
        </Typography> */}
      </CardContent>
      <CardActions disableSpacing>
        
      </CardActions>
      
    </Card>
    </div>
          {/* <div className="row">
            {this.props.data
              ? this.props.data.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                    {" "}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div> */}
        
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
