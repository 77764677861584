import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import AboutUs from './Aboutus';
import Contact from './Contact'
// import Testimonials from './Testimonials';
// import CTA from './CTA';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Hero />
      <Features />
      <AboutUs />
      <Contact />
     
      {/* <Testimonials /> */}
      {/* <CTA /> */}
      {/* <FAQS /> */}
    </Page>
  );
};

export default HomeView;
