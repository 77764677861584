const checkProtect = (protect, ability) => {
    let hasAbility = false;
  if (Array.isArray(protect)) {
    hasAbility = protect.find(p => {
      const { action, subject } = p;
      return ability.can(action, subject)
    })
  } else {
    const { action, subject } = protect;
    hasAbility = ability.can(action, subject);
  }
  return hasAbility;
    // ability.can(protect.action, protect.subject)
  };

export default checkProtect;