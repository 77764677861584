/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'src/store';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
// import {
//   Briefcase as BriefcaseIcon,
//   Calendar as CalendarIcon,
//   ShoppingCart as ShoppingCartIcon,
//   Folder as FolderIcon,
//   BarChart as BarChartIcon,
//   Lock as LockIcon,
//   UserPlus as UserPlusIcon,
//   AlertCircle as AlertCircleIcon,
//   Trello as TrelloIcon,
//   User as UserIcon,
//   Layout as LayoutIcon,
//   Edit as EditIcon,
//   DollarSign as DollarSignIcon,
//   Mail as MailIcon,
//   MessageCircle as MessageCircleIcon,
//   PieChart as PieChartIcon,
//   Share2 as ShareIcon,
//   Users as UsersIcon
// } from 'react-feather';
import {
  Home as HomeIcon,
  User as UserIcon,
  // Calendar as CalendarIcon
  // Mail as MailIcon,
} from 'react-feather';
import checkProtect from 'src/utils/protect';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import ComputerOutlinedIcon from '@material-ui/icons/ComputerOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
// import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutline';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import Logo from 'src/components/Logo';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useAbility from 'src/hooks/useAbility';
// import { subject } from "@casl/ability";
import { getTenantContextReducer } from 'src/slices/tenant';

let sections = [
  {
    items: [
      {
        title: 'Home',
        icon: HomeIcon,
        href: '/app/reports/home'
      },
      {
        title: 'Profile',
        icon: PersonOutlineOutlinedIcon,
        href: '/app/candidate/profile',
        protect: {
          action: 'manage',
          subject: 'candidate-user'
        },
      },
      // {
      //   title: 'Dashboard',
      //   icon: DashboardIcon,
      //   href: '/app/reports/dashboard2',
      //   protect: {
      //     action: 'manage',
      //     subject: 'users'
      //   }
      // },
      // Hiding Availability for now
      // {
      //   title: 'Availability',
      //   icon: CalendarIcon,
      //   href: '/app/management/availability',
      //   protect: {
      //     action: 'manage',
      //     subject: 'availability'
      //   }
      // },
      {
        title: 'Requisitions',
        icon: WorkOutlineOutlinedIcon,
        href: '/app/candidates/candidateRequisitions',
        protect: {
          action: 'manage',
          subject: 'candidate-user'
        }
      },
      {
        title: 'Interviews',
        icon: ComputerOutlinedIcon,
        href: '/app/candidates/candidateInterviews',
        protect: {
          action: 'manage',
          subject: 'candidate-user'
        }
      },
      {
        title: 'Feedbacks',
        icon: FeedbackOutlinedIcon,
        href: '/app/candidates/feedbacks',
        protect: {
          action: 'manage',
          subject: 'candidate-user'
        }
      },
      {
        title: 'Candidates',
        icon: PeopleOutlineIcon,
        href: '/app/management/candidates',
        protect: [{
          action: 'manage',
          subject: 'candidates'
        },{
          action: 'read',
          subject: 'candidates'
        }],
        items: [
          {
            protect: [{
              action: 'manage',
              subject: 'candidates'
            },{
              action: 'read',
              subject: 'candidates'
            }],
            title: 'List',
            icon: FormatListBulletedOutlinedIcon,
            href: '/app/management/candidates',
          },
          {
            title: 'Add Candidate',
            protect: {
              action: 'manage',
              subject: 'candidates'
            },
            icon: PersonAddOutlinedIcon,
            href: '/app/candidate/create'
          }
        ]
      },
      {
        title: 'Interviews',
        icon: ComputerOutlinedIcon,
        href: '/app/management/customers',
        protect: [{
          action: 'manage',
          subject: 'interviews'
        },
        {
          action: 'read',
          subject: 'interviews'
        }],
        items: [
          {
            title: 'List',
            icon: FormatListBulletedOutlinedIcon,
            href: '/app/management/interviews'
          },
          {
            title: 'Schedule',
            protect: {
              action: 'manage',
              subject: 'interviews'
            },
            icon: GridOnOutlinedIcon,
            href: '/app/management/addinterview'
          },
          {
            title: 'Feedbacks',
            icon: FeedbackOutlinedIcon,
            href: '/app/feedbacks'
          }
        ]
      },
      {
        title: 'Requisitions',
        icon: WorkOutlineOutlinedIcon,
        protect: [{
          action: 'manage',
          subject: 'requisitions'
        },{
          action: 'read',
          subject: 'requisitions'
        }],
        items: [
          {
            title: 'List',
            icon: FormatListBulletedOutlinedIcon,
            href: '/app/management/requisitions'
          },
          {
            title: 'New',
            protect: {
              action: 'manage',
              subject: 'requisitions'
            },
            icon: CreateOutlinedIcon,
            href: '/app/management/addrequisition'
          },
          {
            title: 'Decision Making',
            icon: HowToRegOutlinedIcon,
            href: '/app/decision-making',
            protect: {
              action: 'manage',
              subject: 'decision-making'
            }
          },
          // {
          //   title: 'Add Candidate',
          //   icon: PersonAddOutlinedIcon,
          //   href: '/app/candidate'
          // }
        ]
      },
      {
        title: 'Admin',
        icon: LockOutlinedIcon,
        protect: [
          {
            action: 'manage',
            subject: 'users'
          }, 
          {
            action: 'read',
            subject: 'users'
          }, 
          {
            action: 'manage',
            subject: 'tenants'
          }
        ],
        items: [
          {
            title: 'Users',
            icon: UserIcon,
            href: '/management/users',
            protect: [{
              action: 'manage',
              subject: 'users'
            },
            {
              action: 'read',
              subject: 'users'
            }]
          },
          {
            protect: {
              action: 'manage',
              subject: 'tenants'
            },
            title: 'Tenants',
            icon: BusinessOutlinedIcon,
            href: '/management/tenants'
          },
          // {
          //   title: 'Recordings',
          //   icon: VideocamOutlinedIcon,
          //   href: '/app/recordings'
          // }
        ]
      }

      // const sections = [
      //   {
      //     subheader: 'Reports',
      //     items: [
      //       {
      //         title: 'Dashboard',
      //         icon: PieChartIcon,
      //         href: '/app/reports/dashboard'
      //       },
      //       {
      //         title: 'Dashboard Alternative',
      //         icon: BarChartIcon,
      //         href: '/app/reports/home'
      //       }
      //     ]
      //   },
      //   {
      //     subheader: 'Management',
      //     items: [
      //       {
      //         title: 'Tenants',
      //         icon: UsersIcon,
      //         href: '/management/tenants',
      //         items: [
      //           {
      //             title: 'List Tenants',
      //             href: '/management/tenants'
      //           },

      //           {
      //             title: 'View Tenant',
      //             href: '/management/tenant/1'
      //           },
      //           // {
      //           //   title: 'Edit Customer',
      //           //   href: '/app/management/customers/1/edit'
      //           // }
      //         ]
      //       },
      // {
      //   title: 'Customers',
      //   icon: UsersIcon,
      //   href: '/app/management/customers',
      //   items: [
      //     {
      //       title: 'List Customers',
      //       href: '/app/management/customers'
      //     }
      //   ]
      // }
    ]
  }
];

//           {
//             title: 'View Customer',
//             href: '/app/management/customers/1'
//           },
//           {
//             title: 'Edit Customer',
//             href: '/app/management/customers/1/edit'
//           }
//         ]
//       },
//       {
//         title: 'Products',
//         icon: ShoppingCartIcon,
//         href: '/app/management/products',
//         items: [
//           {
//             title: 'List Products',
//             href: '/app/management/products'
//           },
//           {
//             title: 'Create Product',
//             href: '/app/management/products/create'
//           }
//         ]
//       },
//       {
//         title: 'Orders',
//         icon: FolderIcon,
//         href: '/app/management/orders',
//         items: [
//           {
//             title: 'List Orders',
//             href: '/app/management/orders'
//           },
//           {
//             title: 'View Order',
//             href: '/app/management/orders/1'
//           }
//         ]
//       },
//       {
//         title: 'Invoices',
//         icon: ReceiptIcon,
//         href: '/app/management/invoices',
//         items: [
//           {
//             title: 'List Invoices',
//             href: '/app/management/invoices'
//           },
//           {
//             title: 'View Invoice',
//             href: '/app/management/invoices/1'
//           }
//         ]
//       }
//     ]
//   },
//   {
//     subheader: 'Applications',
//     items: [
//       {
//         title: 'Projects Platform',
//         href: '/app/projects',
//         icon: BriefcaseIcon,
//         items: [
//           {
//             title: 'Overview',
//             href: '/app/projects/overview'
//           },
//           {
//             title: 'Browse Projects',
//             href: '/app/projects/browse'
//           },
//           {
//             title: 'Create Project',
//             href: '/app/projects/create'
//           },
//           {
//             title: 'View Project',
//             href: '/app/projects/1'
//           }
//         ]
//       },
//       {
//         title: 'Social Platform',
//         href: '/app/social',
//         icon: ShareIcon,
//         items: [
//           {
//             title: 'Profile',
//             href: '/app/social/profile'
//           },
//           {
//             title: 'Feed',
//             href: '/app/social/feed'
//           }
//         ]
//       },
//       {
//         title: 'Kanban',
//         href: '/app/kanban',
//         icon: TrelloIcon
//       },
//       {
//         title: 'Mail',
//         href: '/app/mail',
//         icon: MailIcon
//       },
//       {
//         title: 'Chat',
//         href: '/app/chat',
//         icon: MessageCircleIcon,
//         info: () => (
//           <Chip
//             color="secondary"
//             size="small"
//             label="Updated"
//           />
//         )
//       },
//       {
//         title: 'Calendar',
//         href: '/app/calendar',
//         icon: CalendarIcon,
//         info: () => (
//           <Chip
//             color="secondary"
//             size="small"
//             label="Updated"
//           />
//         )
//       }
//     ]
//   },
//   {
//     subheader: 'Auth',
//     items: [
//       {
//         title: 'Login',
//         href: '/login-unprotected',
//         icon: LockIcon
//       },
//       {
//         title: 'Register',
//         href: '/register-unprotected',
//         icon: UserPlusIcon
//       }
//     ]
//   },
//   {
//     subheader: 'Pages',
//     items: [
//       {
//         title: 'Account',
//         href: '/app/account',
//         icon: UserIcon
//       },
//       {
//         title: 'Error',
//         href: '/404',
//         icon: AlertCircleIcon
//       },
//       {
//         title: 'Pricing',
//         href: '/pricing',
//         icon: DollarSignIcon
//       }
//     ]
//   },
//   {
//     subheader: 'Extra',
//     items: [
//       {
//         title: 'Charts',
//         href: '/app/extra/charts',
//         icon: BarChartIcon,
//         items: [
//           {
//             title: 'Apex Charts',
//             href: '/app/extra/charts/apex'
//           }
//         ]
//       },
//       {
//         title: 'Forms',
//         href: '/app/extra/forms',
//         icon: EditIcon,
//         items: [
//           {
//             title: 'Formik',
//             href: '/app/extra/forms/formik'
//           },
//           {
//             title: 'Redux Forms',
//             href: '/app/extra/forms/redux'
//           },
//         ]
//       },
//       {
//         title: 'Editors',
//         href: '/app/extra/editors',
//         icon: LayoutIcon,
//         items: [
//           {
//             title: 'DraftJS Editor',
//             href: '/app/extra/editors/draft-js'
//           },
//           {
//             title: 'Quill Editor',
//             href: '/app/extra/editors/quill'
//           }
//         ]
//       }
//     ]
//   }
// ];

function renderNavItems({ items, pathname, depth = 0, ability }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, depth, ability }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, ability }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    if (item.protect && !checkProtect(item.protect, ability)) return acc;
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          ability
        })}
      </NavItem>
    );
  } else {
    if (item.protect && !checkProtect(item.protect, ability)) return acc;
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { tenantContext: tenant } = useSelector(state => state.tenant);
  const { userAvatar } = useSelector(state => state.user);
  const ability = useAbility();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getTenantContextReducer());
  }, [dispatch, user]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/app">
              <Logo style={{ maxWidth: '150px' }} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={userAvatar?.url ? userAvatar.url : user?.avatar}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user?.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {' '}
              {tenant?.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Role: {user?.role}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section, index) => {
            const { protect } = section;
            console.log(section, protect);
            if (protect && !ability.can(protect.action, protect.subject))
              return '';
            return (
              <List
                key={section.items[index].title}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  ability
                })}
              </List>
            );
          })}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/app/management/contactus"
            >
              Contact us
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
