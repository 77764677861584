import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios_remote';

const initialState = {
  rooms: [],
  interviewRooms: [],
  recordings: {},
};

const TWILIO_API = process.env.REACT_APP_TWILIO_API;

const slice = createSlice({
  name: 'twilio',
  initialState,
  reducers: {
    getRoomsReducer(state, action) {
      state.rooms = action.payload.response;
      console.log('getRoomsReducer '+state.rooms);
    },
    getInterviewRooms(state, action) {
      const { rooms } = action.payload;
      state.interviewRooms = rooms;
      state.recordings = {};
    },
    getRecordingsReducer(state, action) {
      const { roomId, recordings } = action.payload;
      if(!state.recordings[roomId])
        state.recordings[roomId] = recordings;
    }
  }
});

export const reducer = slice.reducer;

export const getAllRooms = (name) => async (dispatch) => {
  const response = await fetch(`${TWILIO_API}/rooms`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  const rooms = await response.json()
  console.log('getRooms '+JSON.stringify(rooms));

  dispatch(slice.actions.getRoomsReducer(rooms.data));
};

export const getInterviewRooms = (interviewId) => async (dispatch) => {
  const response = await axios.get(`${TWILIO_API}/room/${interviewId}`);
  const { data: resonseData } = response || {};
  const { data: rooms = [] } = resonseData || {};
  dispatch(slice.actions.getInterviewRooms({ rooms }));
};

export const createComposite = (roomId, data) => async (dispatch) => {
  const response = await axios.post(`${TWILIO_API}/compositions/${roomId}`, data);
  const {data: { data: resonseData = {}}  = {} } = response || {};
  const { sid: compositionId, status } = resonseData;
  return {compositionId, status};
}

export const getCompositMediaURL = (compositionId) => async (dispatch) => {
  const response = await axios.get(`${TWILIO_API}/composition_media/${compositionId}`);
  const {data: { data: resonseData = {}}  = {} } = response || {};
  const { body: { redirect_to: mediaUrl = '' } = {} } = resonseData;
  return mediaUrl;
}

export const getRecordingsReducer = (roomId) => async (dispatch) => {
  const response = await axios.get(`${TWILIO_API}/recordings/${roomId}`);
  const {data: resonseData} = response || {};
  const { data: recordings = [] } = resonseData || {};
  dispatch(slice.actions.getRecordingsReducer({roomId, recordings}));
};

export const getMediaUrl = (roomId, recordingId) => async () => {
  const response = await axios.get(`${TWILIO_API}/media/${roomId}/${recordingId}`);
  try {
    const { data: {data: { body = {}}}} = response;
    const { redirect_to } = body;
    return redirect_to;
  }catch(e){
    return '';
  }
};
export default slice;
