import { combineReducers } from '@reduxjs/toolkit';
import { reducer as tenantReducer } from 'src/slices/tenant';
import { reducer as requisitionReducer } from 'src/slices/requisition';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as availabilityReducer } from 'src/slices/availability';
import { reducer as fileuploadReducer } from 'src/slices/fileupload';
import { reducer as interviewAvailabilityReducer } from 'src/slices/interviewscheduleavailability';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import {reducer as userReducer } from 'src/slices/user';
import {reducer as dashboardReducer } from 'src/slices/dashboard'
import {reducer as interviewReducer } from 'src/slices/interview'
import {reducer as candidateReducer } from 'src/slices/candidates'
import {reducer as feedbackReducer} from 'src/slices/feedback'
import {reducer as twilioReducer} from 'src/slices/twilio'
import { reducer as errorReducer} from 'src/slices/error'
const rootReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  tenant: tenantReducer,
  requisition: requisitionReducer,
  calendar: calendarReducer,
  availability: availabilityReducer,
  fileupload: fileuploadReducer,
  interviewscheduleavailability: interviewAvailabilityReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  interview: interviewReducer,
  candidate: candidateReducer,
  feedback: feedbackReducer,
  twilio: twilioReducer,
  error: errorReducer
});



export default rootReducer;
