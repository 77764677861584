import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAbility from 'src/hooks/useAbility';
import LoadingScreen from 'src/components/LoadingScreen';


const AuthGuard = ({ protect, children }) => {
  const ability = useAbility();
  const { g: loadedAbilities = [] } = ability || {};
  let hasAbility = false;
  if (Array.isArray(protect)) {
    hasAbility = protect.find(p => {
      const { action, subject } = p;
      return ability.can(action, subject)
    })
  } else {
    const { action, subject } = protect;
    hasAbility = ability.can(action, subject);
  }

  if( !loadedAbilities.length  )
    return <LoadingScreen />

  if (!hasAbility) {
    return <Redirect to="/app/protected" />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  protect: PropTypes.object,
  children: PropTypes.node
};

export default AuthGuard;
