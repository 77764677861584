import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'src/store';
import { useSnackbar } from 'notistack';
import { clearErrors } from 'src/slices/error';


export function GlobalErrorWrapper ({children}) {

    const {enqueueSnackbar} = useSnackbar();
    const {errors} = useSelector(state => state.error);
    const dispatch =useDispatch();
    useEffect(()=>{
        if(errors.length>0){
            errors.forEach(error =>{
                enqueueSnackbar(error,{
                    variant: 'error'
                });
            });
        dispatch(clearErrors());
        } 
    },[errors, enqueueSnackbar, dispatch]);
       return <>{children}</>;
}