import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  Badge,
  makeStyles
} from '@material-ui/core';
import {
  Bell as BellIcon,
  // Package as PackageIcon,
  // MessageCircle as MessageIcon,
  // Truck as TruckIcon,
  Video as InterviewIcon,
  Edit3 as FeedbackIcon,
} from 'react-feather';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'src/store';
import { getNotifications, markAsRead } from 'src/slices/notification';
import { getInterview } from 'src/slices/interview';

const iconsMap = {
  Interview_Schedule: InterviewIcon,
  Interview_Feedback: FeedbackIcon,
  Interview_Candidate_Feedback: FeedbackIcon,
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  timestamp: {
    color: theme.palette.primary.main 
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const notifications = [...useSelector((state) => state.notifications).notifications];
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getNotifications());
    }, MINUTE_MS);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [dispatch])

  notifications.sort((a, b) => -a.createdAt.localeCompare(b.createdAt))
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (interviewId) => {
    if(interviewId) 
      dispatch(getInterview(interviewId));
    setOpen(false);
  };

  const handleMarkAsRead = async () => {
    await dispatch(markAsRead());
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={handleOpen}
        >
      <Badge badgeContent={notifications.length} color="secondary">
          <SvgIcon>
            <BellIcon />
          </SvgIcon>
        </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={() => handleClose()}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.notification_type] || BellIcon;
                const message = notification.message.split('at');
                const timestamp = moment.utc(message[1]).tz("America/New_York").format("YYYY-MM-DD HH:mm")
                // console.log(140, message)
                return (
                  <ListItem
                    component={RouterLink}
                    onClick={() => handleClose(notification.interview_id)}
                    divider
                    key={notification.id}
                    to={`/app/management/interview/${notification.interview_id}`}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <div>
                      <ListItemText
                        primary={notification.subject}
                        primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                        secondary={`${message[0]} at ${timestamp} EST`}
                      />
                    <p className={classes.timestamp}>{moment(notification.createdAt).fromNow()}</p>
                    </div>
                    
                  </ListItem>
                );
              })}
            </List>
            <Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                size="small"
                onClick={handleMarkAsRead}
                className={classes.sticky}
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
