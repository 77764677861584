import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffff",
    paddingTop: 150,
    paddingBottom: 150
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(15deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
}
  
}));


const AboutUs = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
              <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div>
              <div className={classes.image}>
              <img
                  alt="Presentation"
                  src="/static/home/Aboutus.svg"
                />
              </div>
              </Box>
              </Grid>
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              {/* <Typography
                variant="overline"
                color="secondary"
              >
                Introducing
              </Typography> */}
               <Typography
           variant="h1"
           color="#0000"
           style= {{ color: "#5850EC"}}
        >
          About Us
        </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  color="#0000"
                >
                  InterviewWorks has been established to elevate and digitize the current Interview process for Recruiters, HR, Hiring Managers, Interviewers, and Interviewees. The SaaS platform leverages innovative technologies, such as Voice recognition, Image Processing, Hiring Analytics enhanced by Machine Learning and AI capabilities. We are continuously adding new capabilities using Agile and Nimble methodologies to enhance the user experience and customer retention.
                </Typography>
              </Box>
            </Box>
          </Grid>
         
        </Grid>
      </Container>
    </div>
  );
};

AboutUs.propTypes = {
  className: PropTypes.string
};

export default AboutUs;