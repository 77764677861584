import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const INTERVIEWWORKS_TENANT_ID = parseInt(process.env.REACT_APP_INTERVIEWWORKS_TENANT_ID);
const PlatformAdminGuard = ({ children }) => {
    const { user, isAuthenticated } = useAuth();


    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }

    console.log('users in guard ' + JSON.stringify(user));

    if (user?.tenant_id !== INTERVIEWWORKS_TENANT_ID && !user.role !== "platform-admin") {
        console.log('Redirecting....');
        return <Redirect to="/app" />;
    }

    return (
        <>
            {children}
        </>
    );
};

PlatformAdminGuard.propTypes = {
    children: PropTypes.node
};

export default PlatformAdminGuard;
