// import moment from 'moment';
//import { colors } from '@material-ui/core';
import mock from 'src/utils/mock';
import { parseQueryString } from './util';
export const sourceofhire = {
  datasets: [
    {
      data: [46, 24, 20, 10],
      backgroundColor: ['#4871ff', '#4b9e86', '#b658f5', '#f07a1a']
    }
  ],
  labels: ['Referral', 'Applied', 'Internal', 'External']
};
export const candidatesineachstage = {
  datasets: [
    {
      data: [46, 24, 20, 10],
      backgroundColor: ['#4871ff', '#4b9e86', '#b658f5', '#f07a1a']
    }
  ],
  labels: ['Interview Process', 'In Review', 'Hired', 'Rejected']
};
// /api\/dashboard2reports\/cards\/?.*/
mock.onGet(/api\/cards\/?.*/).reply((config) => {
  
  const {selectedTimeRange} = parseQueryString(config.url);
  console.log(selectedTimeRange + 'from mock')
  const cards = {
    last_30_days: [
      {
        totalreqs: '100',
        totalusers: '80',
        totalinterviews: '40',
        totalhired: '30'
      }
    ],
    yesterday: [
      {
        totalreqs: '10',
        totalusers: '7',
        totalinterviews: '12',
        totalhired: '3'
      }
    ],
    today: [
      {
        totalreqs: '3',
        totalusers: '2',
        totalinterviews: '8',
        totalhired: '1'
      }
    ],
    last_year: [
      {
        totalreqs: '150',
        totalusers: '100',
        totalinterviews: '200',
        totalhired: '175'
      }
    ]
    
};

  return [200, { cards: cards[selectedTimeRange] }];
});

mock.onGet('/api/dashboard2reports/candidatesineachstage').reply(() => {
  return [200, { candidatesineachstage }];
});

mock.onGet('/api/dashboard2reports/interviewData').reply(() => {
  const interviewData = {
    stats: {
      thisYear: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20],
      lastYear: [12, 11, 4, 6, 2, 9, 9, 10, 11, 12, 13, 13]
    },
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    
  }
  return [200, { interviewData }];
});

mock.onGet('/api/dashboard2reports/applicationsstat').reply(200, {
  applicationsstat: {
    datasets: [
      {
        data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20]
      }
    ],
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
  }
});

mock.onGet('/api/dashboard2reports/numberofinterviews').reply(200, {
  applicationsstat: {
    datasets: [
      {
        data: [10, 16, 5, 8, 3, 14, 14, 4, 17, 19, 18, 20]
      }
    ],
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
  }
});

mock.onGet('/api/dashboard2reports/sourceofhire').reply(() => {
  return [200, { sourceofhire }];
});

mock.onGet('/api/dashboard2reports/numberofsubmissionsbysource').reply(200, {
  applicationsstat: {
    datasets: [
      {
        data: [20, 35, 15, 39, 7, 22, 14, 5, 17, 19, 32, 40]
      },
      {
        data: [25, 14, 19, 8, 3, 37, 12, 23, 40, 10, 36, 20]
      }
    ],
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
  }
});
