import React, { createContext } from 'react';
import { Ability } from '@casl/ability';
import { useSelector } from 'src/store';

const AbilityContext = createContext();


export const AbilityProvider = ({ children }) => {
    const { abilities } = useSelector(state => state.user);
    const ability = new Ability(abilities);
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}

export default AbilityContext;