import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
// import axios from 'src/utils/axios';
import axios from 'src/utils/axios_remote';
const API_ENDPOINT = process.env.REACT_APP_CORE_API;

const initialState = {
  interviews: [],
  interview: {},
  interviewDataCards: [],
  isModalOpen: false
};

const slice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    getInterviewsReducer(state, action) {
      const { interviews } = action.payload;
      // state.interviews = interviews;
      state.interviews = interviews
    },
    getInterview(state,action) {
      const { interview } =action.payload;
      state.interview = interview;
      state.interviews = _.map(state.interviews, _interview => {
        if (_interview.id === interview.id) {
          return interview;
        }
        return _interview;
      });
    },
    createInterview(state, action) {
      const { interview } = action.payload;
      state.interviews = [...state.interviews, interview];
    },
    updateInterview(state, action) {
      const { interview } = action.payload;
      state.interview = interview;
      state.interviews = _.map(state.interviews, _interview => {
        if (_interview.id === interview.id) {
          return interview;
        }
        return _interview;
      });
    },
    // updateFeedbackInterview(state, action) {
    //   const { interview } = action.payload;
    //   console.log(interview);
    //   return {
    //     ...state,
    //     interview: _.map([...state.interviews], (_interview) => {
    //      if (_interview.id === interview.id) {
    //        return {...interview};
    //      }
    //      return {..._interview};
    //    })
    //   }
    // },
    // getInterviewDataCards(state,action) {
    //   const { interviewDataCards } = action.payload;
    //   // state.interviews = interviews;
    //   return {
    //     ...state,
    //     interviewDataCards: interviewDataCards
    //   }
    // },
    deleteInterview(state, action) {
      const { interviewId } = action.payload;
      state.interviews = _.reject(state.interviews, { id: interviewId });
    },
  }
});

export const reducer = slice.reducer;

export const getInterviewsReducer = () => async (dispatch) => {
  const response = await axios.get(`${API_ENDPOINT}/interviews`);
  const { data: interviews } = response;
  dispatch(slice.actions.getInterviewsReducer({interviews}));
};

export const getInterview= (interviewId) => async (dispatch) => {
  const response = await axios.get(`${API_ENDPOINT}/interviews/${interviewId}`);
  const { data: interview } = response;
  dispatch(slice.actions.getInterview({interview}));
};

export const scheduleInterviewStepFunction = async (interview) => {
  await axios.post(`${API_ENDPOINT}/scheduleInterviewStepFunction`, interview);
}

export const createInterview = (data) => async (dispatch) => {
  const response = await axios.post(`${API_ENDPOINT}/interviews`, data);
  const { data: interview } = response;
  await scheduleInterviewStepFunction(interview);
  dispatch(slice.actions.createInterview({interview}));
};

export const updateInterview = (interviewId, update) => async (dispatch) => {
  const response = await axios.put(`${API_ENDPOINT}/interviews/${interviewId}`, update);
  const { data: interview } = response;
  dispatch(slice.actions.updateInterview({ interview }));
};
export const addFeedbacktoInterview = (interviewId, feedbackId) => async (dispatch) => {
  console.log(interviewId, feedbackId)
  const response = await axios.post('/api/interviews/addFeedbacktoInterview', {
    interviewId,
    feedbackId
  });
 
  dispatch(slice.actions.updateFeedbackInterview(response.data));
}
// export const getInterviewDataCards = () => async (dispatch) => {
//   const response = await axios.get('/api/interviews/getInterviewDataCards');
//   console.log(response)
//   dispatch(slice.actions.getInterviewDataCards(response.data));
// };

export const deleteInterview = (interviewId) => async (dispatch) => {
  await axios.delete(`${API_ENDPOINT}/interviews/${interviewId}`);
  dispatch(slice.actions.deleteInterview({ interviewId }));
};
export default slice;
