import mock from 'src/utils/mock';
import moment from 'moment';
import _ from 'lodash';
import { parseQueryString } from './util';

export let users = [
  {
    id: '5ece2cef3e562cbd61996259',
    name: 'Karen Woods',
    avatar: '/static/images/avatars/avatar_5.png',
    email: 'kw@apple.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    role: 'platform-user',
    tenantid: '5e887ac47eed253091be10cb',
    password: 'Secret@123'
  },
  {
    id: '5ece2cf461b9484866f2968c',
    name: 'Sarah Hollingsworth',
    avatar: '/static/images/avatars/avatar_6.png',
    email: 'sh@apple.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    role: 'platform-user',
    tenantid: '5e887ac47eed253091be10cb',
    password: 'Secret@123'
  },
  {
    id: '5ece2cf461b9484866f2969d',
    avatar: '/static/images/avatars/avatar_7.png',
    name: 'Joe Williams',
    email: 'jw@tesla.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    role: 'platform-user',
    tenantid: '5e887b209c28ac3dd97f6db5',
    password: 'Secret@123'
  },
  {
    id: '5ece2cf461b9484866f2970e',
    avatar: '/static/images/avatars/avatar_2.png',
    name: 'Rajvi Desai',
    email: 'rd@tesla.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    role: 'platform-user',
    tenantid: '5e887b209c28ac3dd97f6db5',
    password: 'Secret@123'
  },
  {
    id: '5ece2cf461b9484866f2968f',
    avatar: '/static/images/avatars/avatar_3.png',
    name: 'Jim Cody',
    email: 'jc@tesla.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    role: 'platform-user',
    tenantid: '5e887b209c28ac3dd97f6db5',
    password: 'Secret@123'
  },
  {
    id: '5ece2cf461b9484866f2968g',
    avatar: '/static/images/avatars/avatar_8.png',
    name: 'Jacob Miles',
    email: 'jm@google.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    role: 'platform-user',
    tenantid: '5e86809283e28b96d2d38537',
    password: 'Secret@123'
  },
  {
    id: '5ece2cf461b9484866f2968h',
    avatar: '/static/images/avatars/avatar_10.png',
    name: 'Meghan Hollingsworth',
    email: 'mh@google.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    role: 'platform-user',
    tenantid: '5e86809283e28b96d2d38537',
    password: 'Secret@123'
  },
  {
    id: '5ece2cf461b9484866f2968i',
    avatar: '/static/images/avatars/avatar_11.png',
    name: 'Mary Jones',
    email: 'mj@google.com',
    createdAt: moment()
      .toDate()
      .getTime(),
    role: 'platform-user',
    tenantid: '5e86809283e28b96d2d38537',
    password: 'Secret@123'
  }
];

mock.onPost('/api/users/adduser').reply(request => {
  try {
    const { email, name, password, role, tenantId } = JSON.parse(request.data);
    // console.log(address, email, city, country,name,state,website, status);
    let user = {
      id: '5e887ac47eed253091be10cb123',
      email,
      name,
      password,
      role,
      tenantid: tenantId
    };
    user = [user];

    return [200, { user }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
mock.onPost('/api/users/edituser').reply(request => {
  try {
    const { userId, update } = JSON.parse(request.data);
    let user = null;

    users = _.map(users, _user => {
      if (_user.id === userId) {
        _.assign(_user, { ...update });
        user = _user;
      }

      return _user;
    });

    return [200, { user }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
mock.onPost('/api/users/remove').reply(request => {
  try {
    const { userId } = JSON.parse(request.data);

    users = _.reject(users, { id: userId });

    return [200, { userId }];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
mock.onGet(/api\/users\/?.*/).reply(config => {
  const { tenantId } = parseQueryString(config.url);
  return [200, { users: users.filter(user => user.tenantid === tenantId) }];
});
mock.onGet(/api\/user\/?.*/).reply(config => {
  const { userId } = parseQueryString(config.url);
  return [200, { user: users.filter(user => user.id === userId)[0] }];
});
