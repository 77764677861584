import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  TextField
} from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f7f7f7',
    paddingTop: 30,
    paddingBottom: 50,
    '& .MuiFormLabel-root': { color: 'black' },
    '& .Mui-focused': { color: 'black', borderColor: '1px black groove' }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },

    // textfield: {
    //     border: "1px solid blue",
    //     borderRadius: theme.shape.borderRadius,
    //    // height: "6vh",
    //     padding : theme.spacing(2)
    // }
}));

const Contact = ({ className, ...rest }) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  // const [buttonDisabled, setDisabled] = useState(true);
  const API_ENDPOINT = process.env.REACT_APP_CONTACT_API;
  const { enqueueSnackbar } = useSnackbar();
  const handleEmailOnChange = (value) => {
    if (
      value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmail(value);
      setError(false);
      // setDisabled(false)
    } else {
      setError(true);

    }
  }
  const handleClick = async () => {
    const response = await axios.post(`${API_ENDPOINT}/support`, {
      name,
      email,
      message
    });
    if (response.status === 200) {
      setName('');
      setEmail('');
      setMessage('');
       enqueueSnackbar('Message was sent. Please look out for an email from our support team.', {
         variant: 'success'
         //  action: <Button>See all</Button>
       });
    } 
    console.log(response);
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography style={{ color: '#00000' }} variant="h1" align="center">
          Get in Touch
        </Typography>
        <Typography style={{ color: '#00000' }} variant="body1" align="center">
          Please fill out the form below to send us an email and we will get
          back to you as soon as possible.
        </Typography>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        {/* <Typography
                variant="overline"
                color="secondary"
              >
                Introducing
              </Typography> */}
        {/* <Typography
          variant="h5"
          align="left"
          color="#0000"
        >
          Please fill out the form below to send us an email and we will get back to you as soon as possible.
        </Typography> */}

        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              className={classes.textfield}
              label="Full Name"
              //name="name"
              style={{}}
              required
              value={name}
              variant="outlined"
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              className={classes.textfield}
              label={error ? 'Please enter correct email' : 'Email'}
              //name="email"
              required
              error={error}
              value={email}
              onBlur={e => {
                handleEmailOnChange(e.target.value);
              }}
              onChange={e => setEmail(e.target.value)}
              type="email"
              //value={values.email}
              variant="outlined"
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              className={classes.textfield}
              label="Message"
              //name="message"
              onChange={e => setMessage(e.target.value)}
              required
              value={message}
              //value={values.contact_email}
              variant="outlined"
            />
          </Grid>
          {/* <Grid
                    item
                    md={6}
                    xs={12}
                  >
                  <div >
            <div >
              <h3>Contact Info</h3>
              <br></br>
              <p>
                <h4 style= {{color: "#ffff"}}>Address</h4>
                <h4> 1234 Main st, Alpharetta, GA, 30100</h4>
              </p>
              <br></br>
            </div>
            <div className="contact-item">
              <p>
                 <h4>Phone</h4>
                 <h4> 1234566789</h4>
              </p>
              <br></br>
            </div>
            <div className="contact-item">
              <p>
              <h4>Email</h4>
                 <h4> imterviewworks@io.com</h4>
              </p>
            </div>
          </div> */}
          {/* </Grid> */}
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              
              onClick={handleClick}
            >
              Send Message
            </Button>
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

Contact.propTypes = {
  className: PropTypes.string
};

export default Contact;
