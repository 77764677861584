import { createSlice } from '@reduxjs/toolkit';
import axios_remote from 'src/utils/axios_remote';

const API_ENDPOINT = process.env.REACT_APP_FILE_API;

const initialState = {
uploadFileApiResponse:{
  statusCode: '',
  message: '',
  success: false
},
deleteFileApiResponse:{
  statusCode: '',
  message: '',
  success: false
},
getFileApiResponse:{
  statusCode: '',
  message: 'fetching file',
  success: false,
  url:''
}
};

const slice = createSlice({
  name: 'fileupload',
  initialState,
  reducers: {
    getFile(state, action) {
      const { data = {}, error = null } = action.payload;
      const { url } = data;
      if(error?.statusCode === 404){
        console.log(404);
        state.getFileApiResponse = {
          statusCode: '400',
          message: 'failed',
          success: false,
          url:''
        }
      } else if(url && url !== ''){
        state.getFileApiResponse = {
          statusCode: '200',
          message: 'Success',
          success: true,
          url
        }
      } else{
        state.getFileApiResponse = {
          statusCode: '400',
          message: 'failed',
          success: false,
          url:''
        }
      }
    },
    uploadFile(state, action) {
      const { data = {} } = action.payload;
      const { description, result } = data;
      console.log(action.payload);
      if(result === 'ok'){
        state.uploadFileApiResponse = {
          statusCode: '200',
          message: 'File uploaded successfully.',
          success: true,
        }
      }else{
        state.uploadFileApiResponse = {
          statusCode: '400',
          message: description,
          success: false,
        }
      }
    },
    deleteFile(state) {
      state.deleteFileApiResponse = {
        statusCode: '200',
        message: 'File deleted successfully.',
        success: true,
      }
    }
  }
});

export const reducer = slice.reducer;

export const getFile = (key, candidateId) => async (dispatch) => {
  const  response = await axios_remote.get(`${API_ENDPOINT}/getFile/userId/${candidateId}/key/${key}`);
  console.log('getFile response', response)
  dispatch(slice.actions.getFile(response));
};

export const changeFile = (key, file, candidateId) => async (dispatch) => {
  console.log('changeFile request', {file}, file.name)
        // Create an object of formData
        const formData = new FormData();
        // Update the formData object
        formData.append(
          "file",
          file,
        );
        formData.append(
          "filename",
          // file.name,
          key,
        );
        formData.append(
          "user_id",
          // file.name,
          candidateId,
        );
  const response = await axios_remote.post(`${API_ENDPOINT}/upload`, formData);
  console.log('changeFile API response', {response})
  dispatch(slice.actions.uploadFile(response));
};

export const deleteFile = (key, candidateId) => async (dispatch) => {
  const response = await axios_remote.delete(`${API_ENDPOINT}/deleteFile/userId/${candidateId}/key/${key}`);
  dispatch(slice.actions.deleteFile(response));
};

export default slice;
