import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios_remote';
// import { feedbacks } from 'src/__mocks__/feedbacks'
const initialState = {
  feedbacks: [],
  candidateFeedbacks: [],
  feedback: {},
  clickedCandidateInterview: {},
  isModalOpen: false
};
const API_ENDPOINT = process.env.REACT_APP_CORE_API;

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    getfeedbacksReducer(state, action) {
      const { feedbacks } = action.payload;
      state.feedbacks = feedbacks;
    },
    getFeedback(state, action) {
      const { feedback } = action.payload;
      state.feedback = feedback;
    },
    createfeedback: (state, action) => {
      const { feedback } = action.payload;
      state.feedbacks = [...state.feedbacks, feedback];
    },
    updateFeedback: (state, action) => {
      const { feedback } = action.payload;
      state.feedback = feedback;
      state.feedbacks = _.map(state.feedbacks, _feedback => {
        if (_feedback.id === feedback.id) {
          return feedback;
        }
        return _feedback;
      });
    },
    deleteFeedback(state, action) {
      const { feedbackId } = action.payload;

      state.feedbacks = _.reject(state.feedbacks, { id: feedbackId });
    },

    getCandidateFeedbacks(state, action) {
      const { candidateFeedbacks } = action.payload;

      state.candidateFeedbacks = candidateFeedbacks;
    },
    addCandidateFeedback(state, action) {
      const { feedback } = action.payload;
      state.candidateFeedbacks = [...state.candidateFeedbacks, feedback];
    },
    onCandidateInterviewClick(state, action) {
     // state.clickedCandidateInterview = {};
      state.clickedCandidateInterview = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getfeedbacksReducer = () => async (dispatch) => {
  const response = await axios.get(`${API_ENDPOINT}/feedbacks`);
  const { data: feedbacks } = response;
    dispatch(slice.actions.getfeedbacksReducer({feedbacks}));
};

export const getFeedback = (feedbackId) => async (dispatch) => {
  const response = await axios.get(`${API_ENDPOINT}/feedbacks/${feedbackId}`);
  const { data: feedback } = response;
  dispatch(slice.actions.getFeedback({feedback}));
};

export const deleteFeedback = (feedbackId) => async (dispatch) => {
  await axios.delete(`${API_ENDPOINT}/feedbacks/${feedbackId}`);
  dispatch(slice.actions.deleteFeedback({ feedbackId }));
};


export const createFeedback = (data) => async (dispatch) => {
  const response = await axios.post(`${API_ENDPOINT}/feedbacks`, data);
  const { data: feedback } = response;
  // console.log(response.data);
  dispatch(slice.actions.createfeedback({feedback}));
  return feedback;
};

export const updateFeedback = (feedbackId, update) => async (dispatch) => {
  const response = await axios.put(`${API_ENDPOINT}/feedbacks/${feedbackId}`, update);
  const { data: feedback } = response;
  // console.log(response.data);
  dispatch(slice.actions.updateFeedback({feedback}));
  return feedback;
};

export const getCandidateFeedbacks = () => async dispatch => {
  const response = await axios.get(`${API_ENDPOINT}/getCandidateFeedbacks`);
  const { data: candidateFeedbacks } = response;
  dispatch(slice.actions.getCandidateFeedbacks({ candidateFeedbacks }));
};

export const addCandidateFeedback = data => async dispatch => {
console.log("🚀 ~ file: feedback.js ~ line 104 ~ data", data)
  const response = await axios.post(
    `${API_ENDPOINT}/addCandidateFeedback`,
    data
  );
  const { data: feedback } = response;
  // console.log(response.data);
  dispatch(slice.actions.addCandidateFeedback({ feedback }));
  return feedback;
};

export const addfeedbackclick = interview => async dispatch => {
         console.log(
           '🚀 ~ file: feedback.js ~ line 115 ~ interview',
           interview
         );

         dispatch(slice.actions.onCandidateInterviewClick(interview));
       };

export default slice;
