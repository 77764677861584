import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios_remote';
import { v4 as uuidv4 } from 'uuid';

// import { candidates } from 'src/__mocks__/candidates'
const initialState = {
  candidates: [],
  candidateDashboard: [],
  candidateFeedbacks: [],
  candidateInterviews: [],
  candidateRequisitions: [],
  candidate: {},
  candidateUser: {},
  isModalOpen: false,
  candidateDocs: {},
};

const USERS_API = process.env.REACT_APP_USERS_API;
const API_ENDPOINT = process.env.REACT_APP_CORE_API;
const API_FILE_ENDPOINT = process.env.REACT_APP_FILE_API;

const slice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    getcandidatesReducer(state, action) {
      const { candidates } = action.payload;

      state.candidates = candidates;
    },
    createcandidate(state, action) {
      const { candidate } = action.payload;
      state.candidates = [...state.candidates, candidate];
    },
    updateCandidate(state, action) {
      const { candidate } = action.payload;
      state.candidates = [...state.candidates, candidate];
      state.candidate = candidate;
    },
    getcandidateReducer(state, action) {
      const { candidate } = action.payload;
      state.candidate = candidate;
    },
    getCandidateUserReducer(state, action) {
      const { candidateUser } = action.payload;
      state.candidateUser = candidateUser;
    },
    deleteCandidate(state, action) {
      const { candidateId } = action.payload;

      state.candidates = _.reject(state.candidates, { id: candidateId });
    },
    //For candidate View
    getCandidateDashboard(state, action) {
      const { candidateDashboard } = action.payload;
      console.log('candidateDashboard::::', candidateDashboard);
      // const candidateDetails = candidateDashboard[0]
      // state.candidateInterviews = candidateDashboard[0].interviews;
      state.candidateInterviews.length = 0;
      state.candidateRequisitions.length = 0;
      _.forEach(candidateDashboard, function(candidate) {
        console.log('candidate in slice', candidate);
        state.candidateInterviews = [
          ...state.candidateInterviews,
          ...candidate?.candidate_interviews
        ];
        state.candidateRequisitions = [
          ...state.candidateRequisitions,
          ...candidate?.candidate_requisitions
        ];
        _.forEach(state.candidateInterviews, function(e) {
          if (!e.companyName) {
            e.companyName = candidate.tenant_name;
          }
        });
        _.forEach(state.candidateRequisitions, function(e) {
          if (!e.companyName) {
            e.companyName = candidate.tenant_name;
          }
        });
      });
      state.candidateDashboard = candidateDashboard;
    },
    getCandidateFeedbacks(state, action) {
      const { candidateFeedbacks } = action.payload;
      state.candidateFeedbacks = candidateFeedbacks;
    },
    uploadCandidateDoc(state, action) {
      const { filesData ,candidateId } = action.payload;
      const currentDocuments  = state?.candidateDocs[candidateId]?.documents || []
      let data = { ...(state.candidateDocs[candidateId] || {}) };
      data.documents = [...currentDocuments, ...filesData];
      data.loaded = true;
      state.candidateDocs[candidateId] = {...data};
    },
    getCandidateDos(state, action) {
      const { documents ,candidateId } = action.payload;
      state.candidateDocs[candidateId] = {loaded: true, documents: documents, ...(state.candidateDocs[candidateId] || {})};
    },
    deleteCandidateDoc(state, action) {
      const { document, candidateId } = action.payload;;
      state.candidateDocs[candidateId].documents = _.reject(state.candidateDocs[candidateId].documents, { id: document.id });
    },
    createCandidateV2(state, action) {
      const { candidate } = action.payload;
      state.candidates = [...state.candidates, candidate];
    }
  }
});

export const reducer = slice.reducer;

export const getcandidatesReducer = () => async dispatch => {
  const response = await axios.get(`${API_ENDPOINT}/candidates`);
  const { data: candidates } = response;
  dispatch(slice.actions.getcandidatesReducer({ candidates }));
};
export const getCandidateUserById = (userId) => async dispatch => {
  const response = await axios.get(`${API_ENDPOINT}/candidates/getByUserId/${userId}`);
  const { data: candidate } = response;
  console.log(89, candidate);
  dispatch(slice.actions.getcandidateReducer({ candidate }));
};

export const getcandidateReducer = candidateId => async dispatch => {
  const response = await axios.get(`${API_ENDPOINT}/candidates/${candidateId}`);
  const { data: candidate } = response;
  dispatch(slice.actions.getcandidateReducer({ candidate }));
};

export const getCandidateUserReducer = cadidateUserId => async dispatch => {
  const response = await axios.post(`${USERS_API}/getUser`, {"user_id": cadidateUserId});
  const { data: { response: candidateUser} } = response;
  dispatch(slice.actions.getCandidateUserReducer({ candidateUser }));
  return candidateUser;
};

export const createCandidateUser = data => async dispatch => {
  const response = await axios.post(`${USERS_API}/createCandidateUser`, data);
  const { data: candidate } = response;
  dispatch(slice.actions.createcandidate({ candidate }));
  return candidate;
};

export const deleteCandidate = candidateId => async dispatch => {
  console.log('deleteCandidate', 'candidateId', candidateId);
  await axios.delete(`${API_ENDPOINT}/candidates/${candidateId}`);
  dispatch(slice.actions.deleteCandidate({ candidateId }));
};
export const updateCandidate = (candidateId, data) => async dispatch => {
  const response = await axios.put(`${API_ENDPOINT}/candidates/${candidateId}`, data);
  const { data: candidate } = response;
  dispatch(slice.actions.updateCandidate({ candidate }));
};

export const createCandidate = ( data) => async dispatch => {
  const response = await axios.post(`${API_ENDPOINT}/candidates`, data);
  const { data: candidate } = response;
  dispatch(slice.actions.updateCandidate({ candidate }));
};

export const createCandidateV2 = ( data) => async dispatch => {
    const response = await axios.post(`${API_ENDPOINT}/candidates/v2`, data);
    // console.log(165, response.data);
    const {data: {candidate, candidateFiles: files} }  = response;
    dispatch(slice.actions.updateCandidate({ candidate }));
    return {candidate,  files};
  };

export const getCandidateDashboard = () => async dispatch => {
  const response = await axios.get(`${API_ENDPOINT}/candidates/v2/home`);
  const { data: candidateDashboard } = response;
  dispatch(slice.actions.getCandidateDashboard({ candidateDashboard }));
};

export const getCandidateFeedbacks = () => async dispatch => {
  const response = await axios.get(`${API_ENDPOINT}/getCandidateFeedbacks`);
  const { data: candidateFeedbacks } = response;
  dispatch(slice.actions.getCandidateFeedbacks({ candidateFeedbacks }));
};


export const uploadCandidateDoc = ({ files, candidateId, tenantId, type, callback }) => async (dispatch) => {

  const uploadFilePromise = (file) => {
    const fileKey = uuidv4()
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append( "file", file );
    formData.append( "filename", fileKey );
    formData.append( "user_id", candidateId );
    const candidateFileBody = candidateFileUploadBody(file, fileKey);
    return [axios.post(`${API_FILE_ENDPOINT}/upload`, formData), axios.post(`${API_ENDPOINT}/candidatefiles`, candidateFileBody)];
  }

  const candidateFileUploadBody = (file, fileKey) => {
    return {
      "user_id": candidateId,
      "file_name": file.name,
      "tenant_id": tenantId,
      "file_description": type,
      "file_path": `/getFile/userId/${candidateId}/key/${fileKey}`,
      "file_size": file.size,
      "file_type": file.type,
    }
  } 
  try {
    const filesUploadPromiseArray = files.map(uploadFilePromise).flat(1);
    const filesResponce = await Promise.all(filesUploadPromiseArray);
    const filesData = filesResponce.map(fileRes => fileRes.data).filter(file => file.id);
    console.log(filesUploadPromiseArray);
    await dispatch(slice.actions.uploadCandidateDoc({ filesData, candidateId }));
    callback();
  } catch (e) {
    console.log("Upload File error", e);
  }
};

export const deleteCandidateDoc = ({ file }) => async (dispatch) => {
  try {
    const ownerId = file?.user_id || file?.candidate_id;
    const responses = await Promise.all([ 
      axios.delete(`${API_FILE_ENDPOINT}/deleteFile/userId/${ownerId}/key/${file.id}`), 
      axios.delete(`${API_ENDPOINT}/candidatefiles/${file.id}`)
    ]);
    const { data: document } = responses[1];
    dispatch(slice.actions.deleteCandidateDoc({ document, candidateId: ownerId }));
  } catch (e) {
    console.log("Delete File error", e);
  }
};


export const getCandidateDos = ({ candidateId }) => async (dispatch) => {
  const params = {
    user_id: candidateId
  }
  const response2 = await axios.get(`${API_ENDPOINT}/candidatefiles`, { params });
  const { data: documents } = response2;
  dispatch(slice.actions.getCandidateDos({ documents, candidateId }));

};


export const getCandidateFile = ({file, callback}) => async () => {
  if(file.file_path.includes("getFile")){
    const  response = await axios.get(`${API_FILE_ENDPOINT}${file.file_path}`);
    const { data } = response;
    const { url } = data;
    callback(url);
  }else{
    const  response = await axios.post(`${API_FILE_ENDPOINT}V2/getFile`,{path: file.file_path});
    const { data } = response;
    const { url } = data;
    callback(url);
  }
};
export default slice;
